<template>
  <div v-if="practice && questionOrder" class="pl-3 pr-3">
    <el-row>
      <el-col :span="22">
        <div style="display: flex; flex-wrap: wrap">
          <div
            class="questionOrderLink"
            :style="
              `border: ${
                questionOrder === index + 1
                  ? '#42a16a'
                  : hasAnswer(question)
                  ? '#42a16a60'
                  : '#ddd'
              } 4px solid;`
            "
            @click="() => enterQuestion(index + 1)"
            :key="question.order"
            v-for="(question, index) in practice.exam.questions"
          >
            <b>{{ index + 1 }}</b>
          </div>
        </div>
      </el-col>
      <el-col :span="2">
        <b v-if="accumulationSecond">
          {{
            `${
              practice.has_timing ? this.getRestTime(accumulationSecond) : "--"
            }`
          }}
        </b>
      </el-col>
    </el-row>
    <div class="row">
      <div class="col-sm-6" style="text-align: center">
        <el-button
          @click="() => enterQuestion(questionOrder - 1)"
          :disabled="questionOrder === 1"
          style="width: 90%"
          type="primary"
        >
          Previous
        </el-button>
      </div>
      <div
        v-if="questionOrder === practice.exam.questions.length"
        class="col-sm-6"
        style="text-align: center"
      >
        <el-button
          @click="() => submitPractice(false)"
          style="width: 90%; background: #19a1fb"
          type="primary"
        >
          Submit
        </el-button>
      </div>
      <div v-else class="col-sm-6" style="text-align: center">
        <el-button
          @click="() => enterQuestion(questionOrder + 1)"
          :disabled="questionOrder === practice.exam.questions.length"
          style="width: 90%"
          type="primary"
        >
          Next
        </el-button>
      </div>
    </div>
    <template v-if="testType == 'DigitalSAT'">
      <MultipleChoiceWithPassage
        :passageContent="
          questionAdapter(getCurrentQuestion()).question.passage.content
        "
        :questionOrder="questionOrder"
        :content="questionAdapter(getCurrentQuestion()).question.content"
        :options="questionAdapter(getCurrentQuestion()).question.options"
        :marked="false"
        :answer="answers[0]"
        :showLine="false"
        :showMark="false"
        @onChangeAnswer="onChangeAnswer"
      ></MultipleChoiceWithPassage>
    </template>
    <template v-else>
      <el-row type="flex" justify="space-between">
        <el-col
          v-if="!isMultiple && hasPassage"
          class="border practice-passage"
          :span="12"
        >
          <ViewPassage
            :question="questionAdapter(getCurrentQuestion())"
            :passage="questionAdapter(getCurrentQuestion()).question.passage"
            :type="
              practice.template_type === 'ORIGINAL'
                ? questionAdapter(getCurrentQuestion()).question_type
                : practice.template_type
            "
            :section="
              questionAdapter(getCurrentQuestion()).question.subject.name
            "
          />
        </el-col>
        <el-col class="border" :span="isMultiple || !hasPassage ? 24 : 12">
          <div class="ivy-quesitons">
            <QuestionPractice
              :answers="answers"
              :passage="questionAdapter(getCurrentQuestion()).question.passage"
              :question="questionAdapter(getCurrentQuestion())"
              :defaultQuestionTemplate="practice.template_type"
              @setAnswer="setAnswer"
            />
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import questionAdapter from "@/components/practices/QuestionAdapter";
import practiceApi from "@/apis/practices";
import QuestionPractice from "@/components/practices/QuestionPractice.vue";
import ViewPassage from "@/components/tests/ViewPassage.vue";
import Practice from "@/views/practices/Practice";
import $ from "jquery";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { QuestionPractice, ViewPassage },
  data() {
    return {
      timerInterval: null,
      accumulationSecond: 0,
      practice: null,
      userExam: null,
      answers: [],
      questionOrder: null,
      testType: null
    };
  },
  computed: {
    pageTitle() {
      return this.practice ? this.practice.exam.title : "";
    },
    questionAdapter() {
      return questionAdapter;
    },
    limitTime() {
      return this.practice
        ? this.practice.timing_minutes * 60 +
            Number(this.practice.timing_seconds)
        : Number.MAX_SAFE_INTEGER;
    },
    localStoragePracticeKey() {
      return `PRACTICE_${this.$route.params.practiceId}_TIMER`;
    },
    isMultiple() {
      return (
        questionAdapter(this.getCurrentQuestion()).question.type === "multiple"
      );
    },
    isMathCalculator() {
      return (
        questionAdapter(this.getCurrentQuestion()).question.type === "math"
      );
    },
    hasPassage() {
      return questionAdapter(this.getCurrentQuestion()).question.passage;
    }
  },
  async created() {
    this.questionOrder = Number(this.$route.params.questionOrder);
    await this.fetchPractice();
    await this.fetchUserExam();
    if (!localStorage.getItem(this.localStoragePracticeKey)) {
      const otherStatus = this.userExam.other_status;
      localStorage.setItem(
        this.localStoragePracticeKey,
        otherStatus ? otherStatus[0].progress_time : 0
      );
    }
    this.accumulationSecond = Number(
      localStorage.getItem(this.localStoragePracticeKey)
    );

    this.timerInterval = setInterval(() => {
      const accumulationSecond = Number(
        localStorage.getItem(this.localStoragePracticeKey)
      );
      this.accumulationSecond = accumulationSecond;
      if (
        this.limitTime <= this.accumulationSecond &&
        this.practice.has_timing
      ) {
        clearInterval(this.timerInterval);
        this.submitPractice(true);
      } else {
        localStorage.setItem(
          this.localStoragePracticeKey,
          accumulationSecond + 1
        );
      }
    }, 1000);

    this.getAnswer();
    let type =
      this.practice.template_type === "ORIGINAL"
        ? this.questionAdapter(this.getCurrentQuestion()).question_type
        : this.practice.template_type;
    this.testType = type;
    if (type == "DigitalSAT") {
      if (
        this.getCurrentQuestion().question.options[0].title &&
        this.getCurrentQuestion().question.options[0].title.includes(
          "NO CHANGE"
        )
      ) {
        this.$nextTick(() => {
          this.getOnlinePassage();
        });
      }
    }
    this.scrollToQuestion();
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  methods: {
    onChangeAnswer(value) {
      console.log(value);
      this.answers = [value];
      // if (this.crossOutArr.indexOf(value) > -1) {
      //   let index = this.crossOutArr.indexOf(value);
      //   if (index > -1) {
      //     this.crossOutArr.splice(index, 1);
      //   }
      // }
      // this.answer = value;
      // this.question.question.answer = value;
      // this.question.answer = value;
      // this.question.user_exam_answer_other_status.activities.push({
      //   timestamp: this.instant.formatSecondToMMSS(this.questionTimeLine),
      //   content: `Select ${value}`
      // });
    },
    getOnlinePassage() {
      let vm = this;
      let order = this.getCurrentQuestion().question.order;
      let underLine = $("u");
      let orderObj = {};
      $.each(underLine, function(key, val) {
        let underOrder = 0;
        if (
          $(val)
            .prev(".satQuestionNumber")
            .text() !== ""
        ) {
          underOrder = $(val)
            .prev(".satQuestionNumber")
            .text();
        } else {
          underOrder = $(val)
            .parent("p")
            .prev()
            .find(".satQuestionNumber:last")
            .text();
        }
        underOrder = parseInt(underOrder);
        if (orderObj[`order${underOrder}`]) {
          orderObj[`order${underOrder}`] += $(val).text();
          if (order == underOrder) {
            $(val).text("");
          }
        } else {
          orderObj[`order${underOrder}`] = $(val).text();
          if (order == underOrder) {
            $(val).text("______");
          }
        }
      });
      vm.getCurrentQuestion().question.options[0].title =
        orderObj[`order${order}`];
      vm.getCurrentQuestion().question.tags.forEach((tag, index) => {
        if (tag.id === 3) {
          vm.getCurrentQuestion().question.content =
            "Which choice most logically completes the text?";
        } else if (tag.id === 6) {
          vm.getCurrentQuestion().question.content =
            "Which choice completes the text with the most logical transition?";
        } else if (tag.id === 6) {
          vm.getCurrentQuestion().question.content =
            "Which choice completes the text with the most logical transition?";
        }
      });
      let p = $("#passageContent").find("p");
      $.each(p, function(key, val) {
        $(val).replaceWith(`<span>${$(val).html()}</span>`);
      });
      let u = $("#passageContent").find("u");
      $.each(u, function(key, val) {
        $(val).replaceWith(`<span>${$(val).html()}</span>`);
      });
      $("br").hide();
      const searchRegExp = /&nbsp; &nbsp;/g;
      const replaceWith = "</p><p>";
      const result = $("#passageContent")
        .html()
        .replace(searchRegExp, replaceWith);
      $("#passageContent").html(result);
      let satQuestionNumber = $(".satQuestionNumber");
      $.each(satQuestionNumber, function(key, val) {
        if (order == parseInt($(val).text())) {
          $(val)
            .closest("p")
            .addClass("showP");
        }
      });
      $("#passageContent")
        .find("p")
        .hide();
      $(".showP").show();
      $(".satQuestionNumber").hide();
    },
    scrollToQuestion() {
      let order = this.getCurrentQuestion().question.order;
      let questionOrders = $(".satQuestionNumber");
      let box = document.getElementsByClassName("practice-passage")[0];
      $.each(questionOrders, function(key, val) {
        if (parseInt($(val).text()) == order) {
          box.scrollTo(0, $(val)[0].offsetTop - 10);
        }
      });
    },
    async fetchPractice() {
      const { practice } = await practiceApi.fetchPractice(
        this.$route.params.practiceId
      );
      this.practice = new Practice(practice);
    },
    async fetchUserExam() {
      const { user_exam } = await practiceApi.takePracticeExam(
        this.$route.params.practiceId,
        { user_exam_id: this.$route.params.userExamId }
      );
      this.userExam = user_exam;
    },
    hasAnswer(question) {
      return (
        this.userExam.exam_answers.find(
          ({ exam_question_id }) => exam_question_id === question.id
        ).answers.length !== 0
      );
    },
    getRestTime(accumulationSecond) {
      const restSecond = this.limitTime - accumulationSecond;
      const minutes = String(Math.floor(restSecond / 60)).padStart(2, "0");
      const seconds = String(Math.floor(restSecond % 60)).padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    getCurrentQuestion() {
      if (this.practice) {
        return this.practice.exam.questions.find(
          question => question.order === Number(this.questionOrder)
        );
      }
      return null;
    },
    getAnswer() {
      let answersMap = {};
      this.practice.exam.questions.forEach(({ id }) => {
        answersMap[id] = this.userExam.exam_answers.find(
          ({ exam_question_id }) =>
            exam_question_id === this.getCurrentQuestion().id
        );
      });
      this.answers = answersMap[this.getCurrentQuestion().id].answers;
    },
    setAnswer(newAnswer) {
      this.answers = newAnswer;
    },
    async enterQuestion(questionOrder) {
      let answers = this.answers;
      if (this.isMathCalculator) {
        const mathAnswer = this.answers[0];
        if (mathAnswer) {
          answers = [
            `${mathAnswer[0]}${mathAnswer[1]}${mathAnswer[2]}${mathAnswer[3]}`
          ];
        } else {
          answers = [];
        }
      }

      try {
        clearInterval(this.timerInterval);
        localStorage.removeItem(this.localStoragePracticeKey);

        await practiceApi.postPracticeAnswer(this.$route.params.userExamId, {
          exam_question_id: this.getCurrentQuestion().id,
          is_finished: 0,
          answers: answers,
          other_status: [{ progress_time: this.accumulationSecond }],
          progress_remaining: this.limitTime - this.accumulationSecond
        });
        this.$router.push({
          name: "PracticeQuestion",
          params: {
            practiceId: this.practice.id,
            userExamId: this.$route.params.userExamId,
            questionOrder
          }
        });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async submitPractice(ignoreCheck) {
      if (!ignoreCheck) {
        try {
          await this.$confirm(
            "Are you sure you want to submit your answers?",
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        } catch (e) {
          return false;
        }
      }
      let answers = this.answers;
      if (this.isMathCalculator) {
        const mathAnswer = this.answers[0];
        if (mathAnswer) {
          answers = [
            `${mathAnswer[0]}${mathAnswer[1]}${mathAnswer[2]}${mathAnswer[3]}`
          ];
        } else {
          answers = [];
        }
      }

      try {
        clearInterval(this.timerInterval);
        localStorage.removeItem(this.localStoragePracticeKey);

        await practiceApi.postPracticeAnswer(this.$route.params.userExamId, {
          exam_question_id: this.getCurrentQuestion().id,
          progress_remaining: this.limitTime - this.accumulationSecond,
          other_status: [{ progress_time: this.accumulationSecond }],
          is_finished: 1,
          answers
        });
        this.$router.push({
          name: "PracticeResult",
          params: {
            practiceId: this.practice.id,
            userExamId: this.$route.params.userExamId
          }
        });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scope>
.questionOrderLink {
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  text-align: center;
  line-height: 25px;
  border: #ddd 4px solid;
  margin: 0px 8px 8px 0px;
}

.activeQuestion {
  border: #42a16a 4px solid;
}

.hasAnswerQuestion {
  border: #42a16a60 4px solid;
}

.questionOrderLink:hover {
  border: #42a16a 4px solid;
}

.border {
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0;
  max-height: 600px;
}
.passage-content p.showP {
  white-space: normal;
}
</style>
